import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import { Virtual, Pagination } from "swiper/modules";
import "swiper/css";

import { getPublicAssetURL } from "~/utils/assets";
import "swiper/css/pagination";
const Slide = ({ src }: { src: string }) => {
  const slide = useSwiperSlide();

  if (slide.isActive || slide.isNext || slide.isPrev) {
    return (
      <img
        loading="lazy"
        alt={src}
        className="w-full h-full aspect-square object-cover"
        src={getPublicAssetURL(src)}
      />
    );
  }

  return null;
};

export default function MediaCarousel({
  media,
  direction = "horizontal",
}: {
  media: string[];
  direction?: "horizontal" | "vertical";
}) {
  return (
    <Swiper
      className="w-full h-full aspect-square rounded-xl relative"
      slidesPerView={1}
      direction={direction}
      pagination={{
        clickable: true,
        bulletClass: "swiper-pagination-bullet !bg-default-50",
        bulletActiveClass: "swiper-pagination-bullet-active !bg-default-300",
      }}
      modules={[Virtual, Pagination]}
    >
      {media.map((item, index) => (
        <SwiperSlide key={item} virtualIndex={index}>
          <Slide src={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
