import { cn } from "@nextui-org/react";
import { ReactNode } from "react";
// import { useInView } from "react-intersection-observer";

export default function Book({
  size,
  children,
  isOpen,
}: {
  size: number;
  children: ReactNode;
  isOpen: boolean;
}) {
  return (
    <div>
      <div
        style={{
          perspective: "900px",
          width: `${size}px`,
          paddingLeft: `${size * 0.05}px`,
        }}
        className={cn(
          "group relative transition-all ease animate-duration-800",
          `${isOpen ? "-translate-y-2" : ""}`
        )}
      >
        <div
          style={{
            width: `${size * 0.1}px`,
          }}
          className="absolute top-0 left-0 rounded-l-sm bg-slate-50 ring-2 ring-slate-200 h-full"
        ></div>
        <div
          style={{
            width: `${size * 0.3}px`,
          }}
          className="absolute top-0 right-0 rounded-r-lg  bg-slate-100 ring-2 ring-slate-200 h-full [transform:translateZ(-40px)]"
        ></div>
        <div
          style={{
            transformStyle: "preserve-3d",
            width: `${size}px`,
          }}
          className={cn(
            "h-auto aspect-[4/5] flex justify-center items-center rounded-r-lg origin-left transition-all ease animate-duration-800 bg-slate-50 ring-2 ring-gray-200 shadow-xl",
            `${isOpen ? "[transform:rotateY(-34deg)]" : ""} `
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
