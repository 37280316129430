import MediaCarousel from "./media-carousel";
import { formatFollowerCount } from "~/utils/misc";
import { Result } from "~/stores/search";
import { prettyLocation } from "~/utils/location";
import posthog from "posthog-js";
import { useNavigate } from "@remix-run/react";
import { Skeleton } from "@nextui-org/skeleton";
import { AiOutlineInstagram } from "react-icons/ai";
import { LuMapPin, LuShoppingBag, LuTrendingUp } from "react-icons/lu";
import Book from "./book";
import { Avatar, useDisclosure } from "@nextui-org/react";
import { getPublicAssetURL } from "~/utils/assets";
import BookModal from "./book-modal";
import { useInView } from "react-intersection-observer";

export function ProviderSkeleton() {
  return (
    <div>
      <Skeleton className="rounded-2xl w-full aspect-square" />

      <div className="py-3 flex justify-between">
        <div className="flex-1 flex flex-col gap-y-2">
          <Skeleton className="w-56 h-7 rounded-full" />
          <Skeleton className="w-64 h-5 rounded-full" />
          <Skeleton className="w-36 h-5 rounded-full" />
        </div>
        <Skeleton className="w-10 h-5 rounded-full mt-1" />
      </div>
    </div>
  );
}

export default function Provider({
  slug,
  differentiator,
  name,
  images,
  q,
  instagram_followers,
  location_label,
  is_online,
  offerings,
  direction,
  similarity,
  showSimilarity = false,
  image,
}: Result & {
  q: string | null;
  direction: "horizontal" | "vertical";
  showSimilarity?: boolean;
}) {
  const navigate = useNavigate();

  const { isOpen, onOpenChange } = useDisclosure();
  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: "200px 0px -100px 0px",
  });

  return (
    <div className="relative w-full">
      <div
        role="button"
        ref={ref}
        className="block flex-shrink-0 w-full h-full relative group shadow-none overflow-visible bg-transparent"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            posthog.capture("result_opened", {
              query: q,
              name: name,
              differentiator: differentiator,
              instagram_followers: instagram_followers,
              images,
              location_label,
            });
            navigate(`/p/${slug}${q ? `?q=${encodeURIComponent(q)}` : ""}`);
          }
        }}
        onClick={() => {
          posthog.capture("result_opened", {
            query: q,
            name: name,
            differentiator: differentiator,
            instagram_followers: instagram_followers,
            images,
            location_label,
          });
          navigate(`/p/${slug}${q ? `?q=${encodeURIComponent(q)}` : ""}`);
        }}
      >
        <div className="bg-background rounded-2xl">
          {images && images.length > 0 ? (
            <MediaCarousel media={images} direction={direction} />
          ) : (
            <img
              loading="lazy"
              alt="placeholder"
              className="w-full h-full aspect-square object-cover rounded-2xl"
              src="/assets/blur.webp"
            />
          )}
        </div>

        {/* <div className="absolute top-2 right-2 z-20">
          <Button
            isIconOnly
            className="bg-transparent rounded-full"
            onClick={(e) => {
              e.preventDefault();
              setIsLiked(!isLiked);
            }}
          >
            {isLiked ? (
              <GoHeartFill className="stroke-white fill-red-500 stroke-2 h-5 w-5 " />
            ) : (
              <GoHeartFill className="stroke-white fill-default-500/70 stroke-2 h-5 w-5" />
            )}
          </Button>
        </div> */}
        <div className="py-3">
          <div className="flex items-start justify-between">
            <div className="flex flex-col gap-y-1">
              <h4 className="text-left text-lg font-medium line-clamp-1">
                {name}
              </h4>
              {showSimilarity && (
                <div className="flex items-center gap-x-1 text-default-500">
                  <LuTrendingUp className="size-4" />
                  <span className="block text-sm line-clamp-1">
                    {(similarity * 100).toFixed(2)}% match
                  </span>
                </div>
              )}
              {offerings && offerings.length > 0 && (
                <div className="flex items-center gap-x-1 line-clamp-1 text-default-500">
                  <LuShoppingBag className="size-4" />
                  <span className="block text-sm line-clamp-1">
                    {offerings[0]}
                  </span>
                  {offerings.length > 1 && (
                    <div className="bg-primary-100 text-default-600 rounded-full px-1 flex items-center justify-center text-xs">
                      +{offerings.length - 1}
                    </div>
                  )}
                </div>
              )}
              {/* <div className="flex items-center gap-x-2 text-default-500">
                <div className="flex items-center gap-x-1 shrink-0">
                  <HiSparkles />
                  <span className="text-sm">Award Winning</span>
                </div>

                <div className="flex items-center gap-x-1 shrink-0">
                  <HiSparkles />
                  <span className="text-sm">Female Instructor</span>
                </div>
              </div> */}

              <div className="flex items-center gap-x-1 text-default-500">
                <LuMapPin className="size-4" />
                <span className="block text-sm line-clamp-1">
                  {[is_online ? "Online" : "", prettyLocation(location_label)]
                    .filter(Boolean)
                    .join(" | ")}
                </span>
              </div>

              {/* <Chip
                color=""
                variant="flat"
                startContent={<AiOutlineProduct className="size-4" />}
              >
                Personal Training
              </Chip> */}
            </div>

            {instagram_followers && instagram_followers > 0 && (
              <div className="flex items-center gap-x-1 mt-1">
                <AiOutlineInstagram className="size-4" />
                <p className="text-sm">
                  {formatFollowerCount(instagram_followers)}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="absolute left-4 bottom-[116px] z-10">
        <div
          role="button"
          tabIndex={0}
          onClick={(e) => {
            e.preventDefault();
            posthog.capture("profile_opened", {
              query: q,
              name: name,
            });
            onOpenChange();
          }}
          className="p-0 !scale-1 active:scale-90 transition-all duration-300"
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              onOpenChange();
            }
          }}
        >
          <Book size={55} isOpen={inView}>
            <Avatar
              className="border-1 border-default-500"
              src={image ? getPublicAssetURL(image) : "/assets/blur.webp"}
            />
          </Book>
        </div>
      </div>
      <BookModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        slug={slug}
        q={q}
      />
    </div>
  );
}
