import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Skeleton,
} from "@nextui-org/react";
import { useNavigate } from "@remix-run/react";
import posthog from "posthog-js";
import { useEffect, useState } from "react";
import ProviderCard from "~/routes/p.$slug/components/provider-card";
import ProviderStory from "~/routes/p.$slug/components/provider-story";
import ProviderWork from "~/routes/p.$slug/components/provider-work";
import supabase from "~/utils/supabase";

interface BookModalProps {
  slug: string;
  isOpen: boolean;
  onOpenChange: () => void;
  q: string | null;
}

type Provider = {
  name: string;
  image: string | null;
  id: string;
  work: string;
  basic: string;
  story: string;
  instagram_followers: number;
};

export default function BookModal({
  slug,
  isOpen,
  onOpenChange,
  q,
}: BookModalProps) {
  const navigate = useNavigate();
  const [provider, setProvider] = useState<Provider | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from("providers")
          .select("name, image, id, story, work , basic, instagram_followers")
          .eq("slug", slug);
        if (error) {
          throw error;
        }
        setProvider(data[0] as Provider);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    if (isOpen && provider === null && !loading) {
      init();
    }
  }, [isOpen, loading, provider, slug]);

  const handleViewPress = () => {
    posthog.capture("profile_result_opened", {
      query: q,
      name: provider?.name,
    });
    navigate(`/p/${slug}?q=${q && encodeURIComponent(q)}`);
  };
  return (
    <Modal
      motionProps={{ transition: { name: "fade", duration: 500 } }}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <ModalContent className="max-h-[90dvh] overflow-auto">
        <ModalHeader></ModalHeader>
        <ModalBody>
          {loading ? (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col items-center gap-4 p-6 border rounded-large">
                <Skeleton className="w-28 h-28 rounded-full" />
                <div className="flex flex-col items-center gap-2">
                  <Skeleton className="w-32 h-6 rounded-lg" />
                  <Skeleton className="w-48 h-4 rounded-lg" />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <Skeleton className="w-24 h-5 rounded-lg" />
                <div className="flex flex-col gap-4">
                  {[...Array(4)].map((_, i) => (
                    <div key={i} className="flex items-start gap-4 pl-2">
                      <Skeleton className="w-5 h-5 rounded-lg" />
                      <div className="flex-1">
                        <Skeleton className="w-full h-10 rounded-lg" />
                      </div>
                    </div>
                  ))}
                </div>
                <Skeleton className="w-24 h-5 rounded-lg" />
                <div className="flex flex-col gap-4">
                  {[...Array(2)].map((_, i) => (
                    <div key={i} className="flex items-start gap-4 pl-2">
                      <Skeleton className="w-5 h-5 rounded-lg" />
                      <div className="flex-1">
                        <Skeleton className="w-full h-10 rounded-lg" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            provider && (
              <div className="flex flex-col gap-4">
                <ProviderCard
                  name={provider.name}
                  instagram_followers={provider.instagram_followers}
                  image={provider.image}
                />

                {provider.story && (
                  <>
                    <p className="font-semibold text-base">My Story</p>
                    <ProviderStory story={provider.story} />
                  </>
                )}
                {provider.work && (
                  <>
                    <p className="font-semibold text-base">My Work</p>
                    <ProviderWork work={provider.work} />
                  </>
                )}
              </div>
            )
          )}
        </ModalBody>
        <ModalFooter className="sticky bottom-0 bg-background">
          {loading ? (
            <Skeleton className="w-full h-12 rounded-lg" />
          ) : (
            <Button
              onPress={handleViewPress}
              size="lg"
              color="primary"
              variant="bordered"
              fullWidth
            >
              View Full Profile
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
